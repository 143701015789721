<template>
  <div>
    <el-row>
      <!-- <el-col :span="18" style="text-align:right"> -->
      <!-- <el-dropdown trigger="click" @command="handleCommand"  v-if="myRegionGroup.length" :hide-on-click="false">
                    <span class="el-dropdown-link">
                        {{name}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :class="[ids.includes(item.id)?'activeSelect':'']" v-for="item in myRegionGroup" :key="item.id" :command="item" >
                            <div class="regionGroupList">
                                <span>{{item.name}}</span>
                                <span><i class="el-icon-close" @click.stop="deleRegion(item.id)" v-if="item.editable"></i></span>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
      <!-- </el-col> -->
      <div class="flex">
        <el-select
          v-model="ids"
          multiple
          placeholder="请选择常用"
          @change="handleCommand"
        >
          <el-option
            v-for="item in myRegionGroup"
            :key="item.id"
            :label="item.label"
            :value="item.id"
            class="interestTag_option"
          >
            <span class="tag_name">{{ item.label }}</span>
            <span
              class="tag_delete"
              @click.stop="deleRegion(item.id)"
              ><i class="el-icon-delete"></i
            ></span>
          </el-option>
        </el-select>
        <el-button
          type="text"
          @click.stop="saveAlert"
          :disabled="!list || !list.length"
          >存为常用</el-button
        >
        <!-- <el-button
          type="text"
          @click="f_autoChooseSiteCountries"
          v-if="$route.path == '/ad/creatAds'"
          >自动填充</el-button
        > -->
      </div>
    </el-row>
    <!-- 保存设置 -->
    <el-dialog
      title="保存为常用"
      :visible.sync="saveRegionShow"
      width="600px"
      append-to-body
    >
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
          :rules="rules"
        >
          <el-form-item
            label="名称："
            prop="name"
          >
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="saveRegionShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { savedContents, saveSavedContent, savedContentsDel, siteCountries } from '@/api/creatAd.js';
import {
  querySnapchatCountry,
  interestQuery,
  interestLabelSave,
  interestLabelQuery,
  queryAdAccountByPlatform,
  querySnapchatPixelByAccountId,
  interestLabelDel,
  customAudienceQuery,
} from '@/api/addGoods';
import { mapState } from 'vuex';
let that;
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    isC: {
      type: Boolean,
      default: false,
    },
    savedType: {
      type: String,
      default: 'GEO_REGION',
    },
    geos: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'Object',
    },
    keys: {
      type: String,
      default: '',
    },
    countryCodes: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    disGroup(v, geos) {
      let disAble = false;
      // console.log(this);
      v.map((item) => {
        if (that.geos.includes(item.country_code)) {
          disAble = true;
        }
        // item.country_code
      });
      return disAble;
    },
  },
  data() {
    return {
      ruleForm: {},
      myRegionGroup: [],
      saveRegionShow: false,
      // savedType:'GEO_REGION',
      accountId: this.$store.getters.accountId,
      // name:'常用',
      ids: [],
      countryCodesList: this.countryCodes,
      rules: {
        name: [
          { required: true, message: '请输入兴趣标签名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.data.id,
    }),
    name() {
      return this.ids.length
        ? this.myRegionGroup
            .filter((v) => this.ids.includes(v.id))
            .map((k) => k.name)
            .join(',')
        : '常用';
    },
    campaignInfo() {
      return this.$store.getters['initData/campaignInfo'] || null;
    },
  },
  watch: {
    countryCodes: {
      handler(newV) {
        console.log(newV);
        this.countryCodesList = newV;
      },
      deep: true,
      immediate: true,
    },
    isC: {
      handler(newV) {
        console.log(newV, 'newV');
        if (newV) this.ids = [];
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 自动填充
    async f_autoChooseSiteCountries() {
      console.log(this.list, this.campaignInfo, this.accountId, this.$store.state.num.selectedNum);
      let params = {
        uid: this.$store.state.num.selectedNum,
        aid: this.accountId,
        siteId: this.campaignInfo.site.id,
      };
      let res = await siteCountries(params);
      if (res.code == 0) {
        let arr = res.data;
        this.$emit('set-regions', JSON.stringify(arr.map((item) => item.id)));
      }
    },
    getList() {
      setTimeout(() => {
        console.log('getList');
        console.log(this.countryCodesList.length);
        if (this.countryCodesList.length == 0) {
          this.myRegionGroup = [];
        } else {
          let params = {
            userId: this.userId,
            countryCodes: this.countryCodesList,
            adAccountId: this.$store.getters.accountId,
            // label: this.ids.toString(),
          };

          interestLabelQuery(params).then((res) => {
            console.log(res);
            if (res.data.length) {
              res.data.map((v, index) => {
                if (!v.id) {
                  v.id = v.name + index;
                }
              });
            }
            this.myRegionGroup = res.data;
            console.log(this.myRegionGroup);
          });
        }
      }, 100);
    },
    saveAlert() {
      if (!this.list.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要保存的兴趣标签',
        });
        return false;
      }
      this.saveRegionShow = true;
    },
    // 选择常用语
    handleCommand() {
      let selects = this.ids.length
        ? this.myRegionGroup
            .filter((m) => this.ids.includes(m.id))
            .flatMap((item) => item.labelRelations.map((relation) => relation.id))
        : [];

      console.log(selects, 'vvv');
      let data = [...new Set(selects)];
      console.log(data, 'vvv');
      this.$emit('set-regions', JSON.stringify(data));
    },
    //保存兴趣组
    saveBtn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            // id: 0,
            userId: this.userId,
            countryCodes: this.countryCodesList,
            adAccountId: this.accountId,
            label: this.ruleForm.name,
            interestIds: this.list,
          };
          this.$showLoading();
          interestLabelSave(params).then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '保存成功',
              });
              this.ruleForm.name = '';
              this.saveRegionShow = false;
              this.getList();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //删除兴趣组
    deleRegion(id) {
      this.$showLoading();
      interestLabelDel({ id }).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.ids = this.ids.filter(item => item !== id)
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getList();
        }
      });
    },
  },
  created() {
    console.log('dddddddddddddddddd');
    that = this;
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  ::v-deep.el-select {
    max-width: 180px;
    min-width: 120px;
    margin-right: 10px;
  }
}
.el-dropdown {
  margin: 0 10px;
}
.el-dropdown-menu__item:focus.activeSelect,
.el-dropdown-menu__item.activeSelect {
  background: #ecf5ff;
  color: #66b1ff;
}
.el-dropdown-menu__item.active,
.el-dropdown-menu__item:focus {
  background: transparent;
  color: #606266;
}
.regionGroupList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
}
.el-select-dropdown {
  .el-select-dropdown__list {
    .interestTag_option {
      padding-right: 20px;
      .tag_name {
        float: left;
        margin-right: 15px;
      }
      .tag_delete {
        float: right;
        color: #409eff;
      }

      &::after {
        content: '';
      }
    }
  }
}
</style>
