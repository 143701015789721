<template>
  <el-form label-width="100px">
    <el-form-item label="文件模版">
      <div>
        <el-button
          type="text"
          @click="downFile"
          >下载模板</el-button
        >
        <p class="color-danger lh1">{{ `1、压缩包名称：产品ID#自有填写部分.zip${fileUrl.name}` }}</p>
        <p class="color-danger lh1">2、压缩包文件目录不可调整；</p>
      </div>
    </el-form-item>
    <el-form-item
      label="广告资料"
      v-loading="uploadFileLoading"
    >
      <el-upload
        class="upload-demo"
        action="#"
        ref="upload"
        :on-change="handleChange"
        :before-remove="handleChange"
        :file-list="fileList"
        :http-request="uploadStart"
        accept=".zip"
      >
        <el-button type="primary">点击上传</el-button>
        <div
          slot="file"
          slot-scope="{ file }"
        >
          <div v-if="fileList.length">
            {{ file.name
            }}<span
              style="color: red"
              v-if="file.error"
            >
              ({{ file.error }})
            </span>
            <i
              class="el-icon-close"
              @click="remove(file)"
            ></i>
          </div>
        </div>
      </el-upload>
      <div v-if="zipOssUrl && !fileList.length">
        {{ fileName }}
        <span class="text-sm text-gray-400">(重新上传将会被覆盖)</span>
        <el-button
          type="text"
          @click="downloadOldFile"
          >下载文件</el-button
        >
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
import { uploadZipFiles, checkZipName } from '@/api/addGoods';
import OSS from 'ali-oss';
import moment from 'moment';
import { mapState } from 'vuex';
import { aliossPutFile } from '@/utils/alioss';
export default {
  props: {
    zipOssUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileList: [],
      fileUrl: {
        platform: 'facebook',
        name: '，例如324532#鞋子.zip',
        url: 'https://gamsad.giikin.cn/ads/tmpl/538920%23%E9%AB%98%E6%A1%A3%E4%BC%91%E9%97%B2%E7%94%B7%E5%A3%AB%E7%9F%AD%E8%A2%96-fb-new.zip',
      },
      isRepeat: true, // 用来记录上传的文件是否重复
      uploadFileLoading: false, //上传oss loading
      fileOssUrl1: '', //oss返回文件路径
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    fileOssUrl: {
      get() {
        return this.fileOssUrl1 || this.zipOssUrl;
      },
      set(v) {},
    },
    fileName() {
      if (this.zipOssUrl) {
        let list = this.zipOssUrl.split('/');
        return list[list.length - 1];
      }
    },
  },
  methods: {
    // 上传前验证
    beforeUpload(file) {
      console.log('beforeUpload', file);
      if (!file.type.includes('zip')) {
        this.$message.warning('请上传正确的压缩包格式');
        return Promise.reject();
      }
    },
    // 上传
    uploadZip(params) {
      console.log('ssssssssss');
      let formData = new FormData();
      let canUpload = true;
      this.fileList.forEach((file) => {
        formData.append('file', file.raw);
        if (file.error) {
          canUpload = false;
        }
      });
      formData.append('platform', 'facebook');
      if (!canUpload) return;
      this.$emit('uploadLoading', true);
      this.$showLoading();
      uploadZipFiles(formData)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('上传成功');
            this.fileList = [];
            this.$emit('uploadSuccess');
          }
        })
        .finally(() => {
          this.$hideLoading();
          this.$emit('uploadLoading', false);
        });
    },
    async uploadZipOss() {
      //   const client = new OSS({
      //     accessKeyId: 'STS.NSoHrCv2vm6FWhHkUbiLyUUnV',
      //     accessKeySecret: '7Hg7nGCC3Pv3Ys6vtqdypmvDXUU1FCrwWBo9vwHaJG7F',
      //     stsToken:
      //       'CAISiAN1q6Ft5B2yfSjIr5DaA8j3m+1X2vStVU75j1U3ZcNVupDFtDz2IHhMfnduAuAZt/8/nmpS6vcTlqZ6R4QATFHYao5t9pBQ+A/54XBsRzXvv9I+k5SANTW56XeZtZagj4ybIfrZfvCyESem8gZ43br9cxi7QlWhKufnoJV7b9MRLGbaAD1dH4UUXDkAzvUXLnzML/2gHwf3i27LdipStxF7lHl05NbpoLmV4QGMi0bhmK1H5db+K52/KsBgeYx/XtGn3OFwFNzI2zUC7ANRpuUkzv5L8DLc+9iRRkJR7hOUM+fStZ9NVFYhOPRgQPMc9KiiyKQp57Dp+t6pm0oXD4Z8SD/CQY2s+szAFd6yO8wxHbSBYCyViI7Rbcmq6lh0OyhBaFIbYb0oJmQ1AAEwTXTHMqug8VzauIpcjmgQScjcuLUCLDWAlbLiTzDnyKVETKvU27EW2+I55bNnHH3cWutL2qUAP19rD7qfTYd/AFz7jcatGam2Kl2c/qEnU42Kl5qqC7yKN+2XNhqAARmzjohWa7n+gqLnhGolaCMcd61v0SUu19VdO7RuG9vTmSJ7dLcbqqy4xDQ/nu8SPAmBHEBHcTUTRwm8ho9NgG5lbLqnWI7EXUqGaRQ9NVEJVRrY+zBd3zjMfxKPCoFJeMKBuzvcOo69iotZALEX3Vo1FsBvj+7TTp7S57I8LtKPIAA=',
      //     // 将<YOUR_BUCKET>设置为OSS Bucket名称。
      //     bucket: 'gams-ad-shenzhen',
      //     // 将<YOUR_REGION>设置为OSS Bucket所在地域，例如region: 'oss-cn-hangzhou'。
      //     region: 'oss-cn-shenzhen',
      //     secure: true,
      //   });
      let file = this.fileList[0].raw;
      let date = moment().format('YYYY-MM-DD').slice(5);
      let fileName = `ads/auto-upload/zip/${date}-${this.userInfo.data.id}/${file.name}`;
      console.log(encodeURI(fileName));
      this.uploadFileLoading = true;
      await aliossPutFile(fileName, file)
        .then((res) => {
          this.fileOssUrl1 = res;
          console.log(res);
        })
        .catch((res) => {
          this.fileList = [];
          this.$message({
            type: 'error',
            message: '文件上传失败请稍后重试',
          });
        });
      this.uploadFileLoading = false;
    },
    uploadStart() {
      console.log(111111111);
      this.uploadZipOss();
      // this.$refs.upload.submit();
    },
    remove(file) {
      const i = this.fileList.indexOf(file);
      this.fileList.splice(i, 1);
    },
    handleChange(file, fileList) {
      let newArr = [];
      this.fileList = [fileList[fileList.length - 1]];
    },
    compareFiles(file1, file2) {
      // 比较文件的一些属性，例如文件名、大小等
      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type;
    },
    downFile() {
      const url = this.fileUrl.url;
      window.open(url);
    },
    // 下载编辑的url
    downloadOldFile() {
      let downUrl = this.zipOssUrl.replace('#', '%23');
      window.open(downUrl);
    },
    clear() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
  },
};
</script>
<style lang="scss" scoped>
.lh1 {
  line-height: 1.5;
}
</style>
